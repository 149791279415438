import { Link } from "react-router-dom"

function Nav() {
    const handleLogout = () => {
        localStorage.removeItem('user')
    }
    
    return (
        <div>
            <nav className="navbar navbar-expand-lg white">
                <div className="container-fluid sm" >
                    <a className="navbar-brand" >
                        <div className="logoo">
                            <img src="/logo.png" />
                        </div>
                    </a>
                    <div>
                        <b className="super">Super Admin</b>
                        <Link onClick={handleLogout} to="/"><button className="logoutttt" >
                            <p className="logout-bnl" >SIGN OUT</p>
                            <div className="logout-bnn"  ><img src="/right.png" ></img></div></button>
                        </Link>
                    </div>
                </div>
            </nav>
        </div>
    )
}
export default Nav