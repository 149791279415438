import React, { useEffect, useState } from 'react'
import {Link, useLocation, useNavigate} from "react-router-dom"
import Nav from '../Nav'
import Sidebar1 from './Sidebar1'
import { ToastContainer ,toast } from "react-toastify"

function Item() {
    const navigate = useNavigate()
    const [inputdata , setInput ] = useState()
    
    const location = useLocation()
    const msg = location.state
    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({
            itemvalues: [{}]
        });
    };
    
    const isAuth = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        if (isAuth.email != 'admin@gmail.com') {
            navigate("/")
        }
        toast(msg)
    }, [])

    const handleInput = (e) => {
        setInput(e.target.value)
    }
   
    return (
        <div>
           <Nav />
            <div className="row">
                <div className="col-md-3 col-3">
                    <Sidebar1 />
                </div>
                    <div className="col-md-9 col-9">
                         <section className="inner">
                        <div className="adminLog">
                            <div className="loginBLock">
                                <div>
                                    <div className="nav">
                                        <Link to="/home"><img className="clo"src="/close.png"/></Link>
                                    </div>
                                </div>
                                <center>
                                    <div > 
                                        <img src="/scan_bar.png" className="imeee" /><br></br><br></br> 
                                        <h5 className="in">ITEM BARCODE</h5>
                                        <div><br></br>
                                        <p>
                                        <input  onChange={handleInput} required type="email" className="form-control input-sm" id="email"
                                           
                                            placeholder="Barcode (only number)" />
                                        <i className="far" id="togglePassword"></i>
                                        <button onClick={handleReset} className="imer" >
                                            <img src="/text_clear.png"className="imr"  position="absolute"
                                            ></img></button>
                                    </p>
                                        </div>
                                    </div>
                                    <Link state={inputdata} to="/item-edit"><button type="button" className="bu"><p className="io">Search</p></button></Link>
                                </center>
                                <div className='padiiing'>
                    </div>
                            </div>
                         
                        </div>
                    </section>
                </div>
            </div>
           <ToastContainer />
        </div>
  )
}
export default Item