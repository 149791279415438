
import Nav from "../Nav"
import Sidebar1 from "./Sidebar1"
import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import { ToastContainer, toast } from 'react-toastify';
import { baseUrl } from "../Config";
function Remove3(){
  
    useEffect(() => {
        if (isAuth.email != 'admin@gmail.com') {
            navigate("/")
        }
    }, [])
   
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const [pallet , setPallet] = useState()
    const obj ={ "data":{"serial_number": pallet,"status":1,"encrypted":false},"encoded_data":"yes" }
    const [response , setResponse] = useState()
    const [error , setError] = useState()
    const header ={
        "Content-Type" : "application/json",
        "Authorization" : `Token ${isAuth.token}`
    }
    
    useEffect(() => {
        if (!isAuth) {
            navigate("/")
        }
    }, [])
    const handlePallet = (e) => {
        e.preventDefault()
        setPallet(e.target.value)
        localStorage.setItem("pallet" ,e.target.value)
     
    }

    // const handleRemove = () => {
    //      axios.post(baseUrl+ '/item/remove_item/',obj,{"headers" : header})
    //     .then(response => setResponse(response.data.data))
    //     .catch(error => setError(error.response))
    //     console.log(error,"obbbbbbbbbbbb")
        
    // }
    const handletoggle = () => {
        {
          
            axios.post(baseUrl + '/item/change_invetory_status/', obj, { "headers": header })
                .then(response => {
                    setResponse(response.data.data)
                    console.log(response)
                })

        }
    }
    if (error) {
        toast("PALLET ID IS INCORRECT")
        setError("")
        
    }
    if (response) {
        localStorage.setItem("msg" ,"PALLET ITEM REMOVED SUCCESSFULLY")
        navigate("/proceed")

    }
    const handlemsg = () => {
        toast("PLEASE ENTER PALLET ID")
    }
    return(
        <div>
       <Nav />
         <div className="row">
        <div className="col-md-3 col-3">
         <Sidebar1 />
         </div>
         <div className="col-md-9 col-9"> <section className="inner">
                <div className="adminLog" >
                    <div className="loginBLock" >
                    <div className="row">
                <div className="col-md-10 col-10"></div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
                        <center>
                     
                            <img src="/delete.png" className="im"/><br></br><br></br>
                                <h5 className="in">ENTER PALLET ID</h5>
                                <div >
                                  
                                  <div className="inpu">
                                    <p>
                                        <input onChange={handlePallet} type="text"
        pattern="[0-9]*" className="form-control input-sm" id="email"
                                           
                                            placeholder="Enter Pallet id (only number)" />
                                       
                                    </p></div>
                                   
                                </div>
                                <div className="row">
                            <div className="col-md-4 col-4"></div>
                            <div className="col-md-4 col-4">
                                <div >
                            {
                                       pallet ? 
                               
                                <Link to="" state={obj}><button type="button" onClick={handletoggle}  className="buttt"><p className="io">Remove</p></button></Link>
                            :
                            <Link to=""  onClick={handlemsg}><button type="button"   className="buttt"><p className="io">Remove</p></button></Link>
                               }     </div>
                               </div>  <div className="col-md-4 col-4"></div>
                               </div>

                        </center>
                    <div className="padiing">
                    </div>
                    <ToastContainer />

                </div>
            </div>
        </section></div></div>
       
    </div>
    )
}
export default Remove3