



import Sidebar1 from './Sidebar1';
import Nav from '../Nav';
import { useState ,useEffect} from 'react';

import { Link,  useNavigate } from "react-router-dom"
import React from "react";
import { DateRange } from 'react-date-range';
import axios from 'axios';
import { baseUrl } from '../Config';
import { CSVLink } from 'react-csv';

function Download2() {
  const isAuth = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate()

  useEffect(() => {
      if (!isAuth) {
        navigate("/");
      }
      
    }, []);
 
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
    
  }, []);

const [date, setDate] = useState(new Date());
 const [list,setList] = useState([])
 const [data,setData] = useState()
 const [data_new,setDatanew] = useState([])
 const [downloaded,setDownl] = useState(false)
 const [exportt,setExpo] = useState()

  




const [state, setState] = useState([
  
  {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }
  
]

);

const datee = state[0].startDate.getFullYear() + '-' + (state[0].startDate.getMonth() + 1) + '-' + state[0].startDate.getDate();
const dateee = state[0].endDate.getFullYear() + '-' + (state[0].endDate.getMonth() + 1) + '-' + state[0].endDate.getDate();



const body = {"data":{"from_date":datee ,"to_date":dateee,"encrypted":false},"encoded_data":"yes"}
const header ={
  "Content-Type" : "application/json",
  "Authorization" : `Token ${isAuth.token}`
}



 const download = async (e) => {
  
  e.preventDefault()
 
  await axios.post(baseUrl + '/item/filter_manifest/' , body , {"headers" : header})
  .then(response => {
    setList(response.data.data)
  })

}



useEffect(()=> {
if(list.length >= 1) {
  navigate('/downloadList' ,{"state" : list})
}

})

const changeState =() => {
  setExpo(0)
}

const changeState1 =() => {
  setExpo(1)
}


const getFile = (e) => {
  
  
 
  const file = e.target.files[0]



  const header ={
    "Content-Type" : "application/json",
    "Authorization" : `Token ${isAuth.token}`
  }
  

  var formData = new FormData(); 
  formData.append("itemsheet", file);
  formData.append("encrypted", false);
  
  axios.post(baseUrl + '/item/filter_item_list/' , formData , {"headers" : header} )
  .then(response => {
    
    setData(response.data.data.data)
    response.data.data.data.forEach(item => setDatanew(prev=> [...prev , item.item ]))
    
  })


}








// if (data_new && downloaded === false)  {

  const headerss = [
    { label: "serialnumber", key: "serial_number" },
    { label: "product_name", key: "product_name" },
    { label: "barcode", key: "item_barcode" },
    { label: "title", key: "tgt_title" },
    { label: "msrp", key: "tgt_msrp" },
    { label: "category", key: "tgt_category" },
    { label: "brand", key: "tgt_brand" },
  ];

  const csvReport = {
    data: data,
    headers: headerss,
    filename: 'data.csv'
  };


  // setDownl(true)





  return (
    <div>
      <Nav />
      <div className="row">
        <div className="col-md-3 col-3">
          <Sidebar1 />
        </div>
        <div className="col-md-9 col-9">    
          <section className="inner">
            <div className="adminLog">
              <div className="loginBLock">
              <div className="row">
                <div className="col-md-10 col-10">
                <div><h5 className="ine">Select Date</h5></div>
                </div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
                <center>
                <div className='app'>
                <div className='calendar-container'>

                  <div className='row'>
                    <div className='col-md-1'></div>

                  <div onClick={changeState}  className='col-md-11 pick mt-4'>

                    
                  <DateRange editableDateInputs={true}

                    onChange={item => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}


                    />
                    </div>
                  </div>
                  
                </div>
              </div></center>
              <div className="row">
              <div className="col-md-2 col-2"></div>
              <div className='col-md-4 col-4'>
                            <div className=" browse-profile-cont margin-bottom-30">
                                <div className="input-style" >
                                <img src="/upload.png" className="clee"></img>
                                    <input className="inputUpload" type="text" placeholder="Choose File"/>
                       
                                </div>
                                <input onClick={changeState1}  onChange={getFile} type="file" className="browse-profile"/>
                            </div>
                        </div>
              <div className="col-md-4 col-4" style={{"textAlign" : "-webkit-left"}}>
                {
                  (exportt === 1 && data_new) ? 
                  <CSVLink filename='data.csv' data={data_new} headers={headerss}><button type="button" style={{"width" : "245px"}}  className="buty"><p className="io">Download</p></button></CSVLink>
                  :
                  <button type="button" onClick={download} style={{"width" : "245px"}} className="buty"><p className="io">RUN</p></button>
                }
               
               
               </div>
               <div className="col-md-2 col-2"></div>
                <div className='pading'>
                    </div></div>
              </div>
            
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
export default Download2