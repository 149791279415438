import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { baseUrl } from '../Config'
import Nav from '../Nav'
import Sidebar1 from './Sidebar1'
import { ToastContainer, toast } from "react-toastify"
import { Button, Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'

function Signup() {
  const [pw, setPw] = useState("")
  const [user, setU] = useState("")
  const [email, setE] = useState("")
  const [utype, setType] = useState("")
  const [status, setStatus] = useState("")
  const [login_code, setUc] = useState()
  const [show, setSh] = useState(false)
  const [data, setArray] = useState([])
  const [err, setErr] = useState("")
  const [errorrs, setErrorrs] = useState("")
  const [errors, setErrors] = useState("")
  const [erros, setErros] = useState("")


  const validate_field = () => {


    if (user == "" && pw == "" && utype == "" && status == "") {
      setErr("User is required")
      setErrors("Password is required")
      setErrorrs("User Type is required")
      setErros("Status is required")

      return false

    }
    else if (pw == "" && utype == "" && status == "") {
      setErrors("Password is required")
      setErrorrs("User Type is required")
      setErros("Status is required")

      return false

    }
    else if ( utype == "" && status == "") {
      
      setErrorrs("User Type is required")
      setErros("Status is required")

      return false

    }
    else if (status == "") {
     
      setErros("Status is required")

      return false

    }
    else if ( utype == "") {
      
      setErrorrs("User Type is required")
    

      return false

    }
    else if (user == "" ) { 
      setErr("User is required")
     

      return false

    }
    else if (pw == "" ) {
      setErrors("Password is required")
     

      return false

    }




    return true
  }

  const navigate = useNavigate()

  const isAuth = JSON.parse(localStorage.getItem('user'))
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Token ${isAuth.token}`
  }
  useEffect(() => {
    if (isAuth.email != 'admin@gmail.com') {
      navigate("/")
    }
  }, [])

  const handleUserid = (e) => {
   
  }
  const handlePw = (e) => {
    const value = e.target.value
    setPw(value)
    setErrors("")
  

  }
  const handleU = (e) => {
    const value = e.target.value.replace(/[^a-z]/gi, '')
    setU(value)
    setErr("")

  }
  const handleE = (e) => {
    const value = e.target.value
    setE(value)

  }
  const handleTy = (e) => {

    const value = e.target.value
    setType(value)
    setErrorrs("")

  }
  const handleSta = (e) => {

    const value = e.target.value
    setStatus(value)
    setErros("")
 
  }

  const navtolist = () => {
    navigate("/user")
  }

  const closePopup = (e) => {
    setSh(false)
    setTimeout(navtolist, 2000);
  }
  const handleSubmit = (e) => {

    e.preventDefault()
    if (validate_field()) {
      const obj = {
        "data": {
          "email": "abc@email.com",

          "password": pw,

          "user_type": utype,

          "username": user,

          "user_permission": data,

          "encrypted": false

        }, "encoded_data": "yes"
      }

      axios.post(baseUrl + '/signup/', obj)
        .then(response => {
          // toast(response.data.message)
          // setTimeout(navtolist, 5000);
          const obj = {
            "data": {
              "encrypted": false
            },
            "encoded_data": "yes"
          }
          axios.post(baseUrl + '/user-list/', obj, { "headers": header })
            .then(response => {

           
              const found = response.data.data.find(obj => {
                return obj.username == user
              })
              setUc(found.user_code)
              setSh(true)
            })

        })

    }
  }
  const handleCheck = (e) => {
    // Destructuring
    const { value, checked } = e.target;


    
    // Case 1 : The user checks the box
    if (checked) {
      setArray([...data, value]);
    }
    // Case 2  : The user unchecks the box
    else {
      data.pop()

    }
  };
  return (
    <div> < Nav />
      <div className="row">
        <div className="col-md-3 col-3">
          <Sidebar1 />
        </div>
        <div className="col-md-9 col-9">
          <section className="inner" >
            <div className="container">
              <div className="row mar-t">
                <div className="col-md-2">
                </div>

                <td> <button onClick={handleSubmit}  type="button" className="save">Save</button></td>
                <td> <Link to="/user"><button  type="button" className="cancle">Cancel</button></Link></td>
                <div className="col-md-2">
                </div>

                <div className="loginblock" >
                  <div ><form>
                    <div className="row ">
               

                    </div>
                    <div className="row ">
                      <div className="col">
                        <label className='sign' >Username</label>
                        <input type="text" onChange={handleU} className="form-control input"  placeholder="Username here" />
                        <p className='errr'>{err}</p>
                      </div>

                      <div className="col">
                        <label className='sign'>User PW</label>
                        <input type="text" onChange={handlePw} className="form-control input"  placeholder="User PW" />
                        <p className='errr'>{errors}</p>
                      </div>
                    </div>
    
   

                    <div className="row ">
                      <div className="col">
                        <label className='sign'>User Type</label>
                        <select onChange={handleTy} required className="form-select" id="inputGroupSelect01">
                        <option value="5">Select the User Type</option>
                          <option value="4">Associate</option>
                          <option value="3">Supervisor</option>
                          <option value="2">Manager</option>
                        </select>
                        <p className='errr'>{errorrs}</p>
                      </div>
                      <div className="col">
                        <label className='sign'>User Status</label>
                        <select onChange={handleSta} className="form-select" id="inputGroupSelect01">
                        <option value="3">Select the status</option>
                          <option value="1">Active</option>
                          <option value="2">Inactive</option>

                        </select>
                        <p className='errr'>{erros}</p>

                      </div>
                    </div>


                    <ToastContainer />

                  </form>
                  </div></div>
              </div></div></section></div></div>



      <Modal
        show={show}
        onHide={closePopup}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>USER CREATED SUCCESSFULLY</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          YOUR USER ID FOR LOGIN IS <b>{login_code}</b>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={closePopup}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>

      <div></div>
    </div>

  )
}
export default Signup