import { useEffect,useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { NavLink } from 'react-router-dom';

import { useLocation } from "react-router-dom";



function Sidebar1() {
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const handleLogout=()=>{
    localStorage.removeItem('user')

}
const handleClick1 = () => {
  setIsActive1(current => !current);
};
const location = useLocation();

//destructuring pathname from location
const { pathname } = location;

//Javascript split method to get the name of the path in array
const splitLocation = pathname.split("/")
  return (
    <div  className="sidebar" >
        
        <hr className="top_hr"/>
        <div >
            <ul className="side">
            <li className={splitLocation[1] === "home" ? "active" : ""}>
            <Link to="/home" className="sideb">
                  <div className="admin_li" >
                    <div className={splitLocation[1] === "home" ? "activedashboard" : "deactivedashboard"}>
                   
                    <span className={splitLocation[1] === "home" ? "act" :"dash"}>Dashboard</span>
                  </div></div>
              </Link> 
                </li>
                
   
                <li className={splitLocation[1] === "new" ? "activenew" : ""}>
                <Link to="/new" className="sideb">
                    <div className="admin_li"  >
                    <div className={splitLocation[1] === "new" ? "activeenew" : "deactivenew"}>
                    <span className={splitLocation[1] === "new" ? "act" :"newww"}>New</span>
                    </div>
                    </div>
                    </Link>
                </li>
       
                <li className={splitLocation[1] === "manifeststart" ? "activemanifest" : ""}>
                    
                <Link to="/manifeststart"className="sideb" > <div className="admin_li">
                <div className={splitLocation[1] === "manifeststart" ? "activeemanifest" : "deactivemanifest"}>
                    <span  className={splitLocation[1] === "manifeststart" ? "act" :"manifesttt"}>Manifest</span>
                    </div>
                    </div></Link>
                </li>
 
                <li className={splitLocation[1] === "reprint" ? "activereprint" : ""}>
                <Link to="/reprint"className="sideb"><div className="admin_li">
                <div className={splitLocation[1] === "reprint" ? "activeereprint" : "deactivereprint"}>
                    <span className={splitLocation[1] === "reprint" ? "act" :"reprinttt"}>Reprint</span>
                    </div></div>
                    </Link>
                </li>
        
                <li className={splitLocation[1] === "remove" ? "activeremove" : ""}>
                <Link to="/remove"className="sideb"><div className="admin_li">
                <div className={splitLocation[1] === "remove" ? "activeeremove" : "deactiveremove"}>
                    <span className={splitLocation[1] === "remove" ? "act" :"removeee"}>Remove</span>
                    </div></div>
                    </Link> 
                </li>
    
                <li className={splitLocation[1] === "download" ? "activedownload" : ""}>
                    
                <Link to="/download"className="sideb">
                    <div className="admin_li">
                    <div className={splitLocation[1] === "download" ? "activeedownload" : "deactivedownload"}>
                    <span className={splitLocation[1] === "download" ? "act" :"downloaddd"}>Download</span>
                    </div>
                    </div></Link>
                </li>

                <li className={splitLocation[1] === "user" ? "activeuser" : ""}>
                <Link to="/user"className="sideb">
                    <div className="admin_li">
                    <div className={splitLocation[1] === "user" ? "activeeuser" : "deactiveuser"}>
                    <span className={splitLocation[1] === "user" ? "act" :"userrr"}>User</span>
                    </div>
                    </div></Link>
                </li>
          
                <li className={splitLocation[1] === "item" ? "activeitem" : ""}>
                <Link to="/item"className="sideb">
                    <div className="admin_li">
                    <div className={splitLocation[1] === "item" ? "activeeitem" : "deactiveitem"}>
                    <b className={splitLocation[1] === "item" ? "act" :"itemmm"}>Item</b>
                    </div>
                    </div></Link>
                </li>
      
                <li className={splitLocation[1] === "inventory" ? "activeinventory" : ""}>
                    
                <Link to="/inventory/list" className="sideb">
                    <div className="admin_li">
                    <div className={splitLocation[1] === "inventory" ? "activeeinventory" : "deactiveinventory"}>
                    <span className={splitLocation[1] === "inventory" ? "act" :"inventoryyy"}>Inventory</span>
                    </div>
                    </div></Link>
                </li>
                {/* <Link to="/home" className="sideb">
                  <li className="admin_li" ><img  src="/noun-graph-3282938.png" ></img>
                    <span className="dash">Dashboard</span>
                    </li>
                  </Link> */}


                   {/* <Link to="/new" className="sideb">
                    <li className="admin_li"  ><img  src="/Icon awesome-box.png"  ></img>
                    <span className="newww">New</span>
                    </li>
                    </Link>

                    <hr className="hr_line" />
                    
                    
                   <Link to="/manifeststart"className="sideb" > <li className="admin_li"><img  src="/Group 209.png"></img>
                    <span  className="manifesttt">Manifest</span>
                    </li></Link> */}

     
{/* 
                    <Link to="/reprint"className="sideb">   <li className="admin_li"><img  src="/Group 210.png"></img>
                    <span className="reprinttt">Reprint</span>
                    </li>
                    </Link>

                    <hr className="hr_line" />

                   <Link to="/remove"className="sideb"><li className="admin_li"><img  src="/Icon material-delete.png"></img>
                    <span className="removeee">Remove</span>
                    </li>
                    </Link> 

                    <hr className="hr_line" />
                    <Link to="/download"className="sideb">
                    <li className="admin_li"><img  src="/Icon ionic-ios-download.png"></img>
                    <span className="downloaddd">Download</span>
                    </li></Link>

                    <hr className="hr_line" />
                    <Link to="/user"className="sideb">
                    <li className="admin_li"><img  src="/Icon awesome-user-alt.png"></img>
                    <span className="userrr">User</span>
                    </li></Link>

                    <hr className="hr_line" />

                    <Link to="/item"className="sideb">
                    <li className="admin_li"><img  src="/Group 211.png"></img>
                    <span className="itemmm">Item</span>
                    </li></Link>

                    <hr className="hr_line" />
                    <Link to="/inventory/list" className="sideb">
                    <li className="admin_li"><img  src="/inventorys.png"></img>
                    <span className="inventoryyy">Inventory</span>
                    </li></Link> */}


                  
                    
            </ul>
            {/* <center>      <Link onClick={handleLogout} to="/"><button className="logoutttt" >
                      <p className="logout-bnl" >SIGN OUT</p>
                        <div className="logout-bnn"  ><img  src="/right.png" ></img></div></button>
                    </Link></center> */}
        </div>
       
    </div>
  )
}

export default Sidebar1