import {BrowserRouter,Route,Routes} from "react-router-dom"
import Login from "./Login"
import Home3 from "./Admin/Home3"
import New3 from "./Admin/New3"
import Manifest_start3 from "./Admin/Manifest_start3"
import Manifest_stop3 from "./Admin/Manifest_stop3"
import Reprint3 from "./Admin/Reprint3"
import Remove3 from "./Admin/Remove3"
import Proceed3 from "./Admin/Proceed3"
import Download from "./Admin/Download2"
import User from "./Admin/User"
import Item from "./Admin/Item"
import Itemedit from "./Admin/Itemedit"
import Signup from "./Admin/Signup"
import Printpage from "./Printpage"
import Reprintt from "./Reprintt"
import Download_list from "./Admin/Download_list"
import Useredit from "./Admin/Useredit"
import InventoryL from "./Admin/InventoryL"





function Router(){

   

    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login/>}></Route>
                <Route path="/barcode" element={<Printpage />}></Route>
                <Route path="reprint/barcode" element={<Reprintt />}></Route>
                <Route path="/home" element={<Home3/>}></Route>
                <Route path="/new" element={<New3/>}></Route>
                <Route path="/manifeststart" element={<Manifest_start3/>}></Route>
                <Route path="/manifeststop" element={<Manifest_stop3/>}></Route>
                <Route path="/reprint" element={<Reprint3/>}></Route>
                <Route path="/remove" element={<Remove3/>}></Route>
                <Route path="/proceed" element={<Proceed3/>}></Route>
                <Route path="/download" element={<Download/>}></Route>
                <Route path="/downloadlist" element={<Download_list/>}></Route>
                <Route path="/user" element={<User/>}></Route>
                <Route path="/item" element={<Item/>}></Route>
                <Route path="/item-edit" element={<Itemedit/>}></Route>
                <Route path="/signup" element={<Signup/>}></Route>
                <Route path="/user/edit" element={<Useredit/>}></Route>
                
          
     
                <Route path="/inventory/list" element={<InventoryL />}></Route>
        
            </Routes>
        </BrowserRouter>
    )
}
export default Router