import axios from 'axios'
import React, { useEffect, useState} from 'react'
import { Link, useLocation } from "react-router-dom"
import {  useNavigate } from "react-router-dom"
import { baseUrl } from '../Config'
import Nav from '../Nav'
import Sidebar1 from './Sidebar1'
import { ToastContainer ,toast } from "react-toastify"

function Itemedit() {
  const location = useLocation()
  const navigate = useNavigate()
  const [data , setData] =useState({

  })

  const [brand , setBrand] = useState("")
  const [category , setCategory] = useState("")
  const [color , setColor] = useState("")
  const [title , setTitle] = useState("")
  const [model , setModel] = useState("")
  const [size , setSize] = useState("")
  const [weight , setWeight] = useState("")
  const [msrp , setMsrp] = useState("")
  const [Saleprice , setSprice] = useState("")
  const [targetowned , setOwned] = useState("")
  const [weightcode , setWeightCode] = useState("")
  const [height , setH] = useState("")
  const [width , setW] = useState("")
  const [depth , setD] = useState("")
  const [unit , setU] = useState("")
  const [updated , setUpdated] = useState("")
  
  
  const inputdata = location.state


  const isAuth = JSON.parse(localStorage.getItem('user'))
  const header ={
    "Content-Type" : "application/json",
    "Authorization" : `Token ${isAuth.token}`
    }
  
    useEffect(() => {
        if (isAuth.email != 'admin@gmail.com') {
            navigate("/")
        }
    }, [])

  

    useEffect(() => {
      const obj = {"data":{"item_barcode": inputdata,"encrypted":false},"encoded_data":"yes"} 
      axios.post(baseUrl + '/item/get_item/' , obj , {"headers" : header} )
      .then((response)=>{
      
        const a=response.data.data
        
        setData(a)
        setBrand(a.tgt_brand)
        setCategory(a.tgt_category)
        setColor(a.tgt_color)
        setTitle(a.tgt_title)
        setModel(a.tgt_model)
        setSize(a.tgt_size)
        setWeight(a.tgt_weight.value)
        setMsrp(a.tgt_msrp)
        setSprice(a.tgt_saleprice)
        setOwned(a.targetowned)
        setWeightCode(a.tgt_weight.code)
        setH(a.tgt_dimensions.height)
        setW(a.tgt_dimensions.width)
        setD(a.tgt_dimensions.depth)
        setU(a.tgt_dimensions.units)
        
        
    } , (error)=>{
          navigate('/item' , {state: "No data found"})

    })
    },[])


  const changeBrand = (e) => {
    setBrand(e.target.value)
  }
  const changeCategory = (e) => {
    setCategory(e.target.value)
  }
  
  const changColor = (e) => {
    setColor(e.target.value)
  }

  const changTitle = (e) => {
    setTitle(e.target.value)
  }

  const changeModel = (e) => {
    setModel(e.target.value)
  }

  const changeSize = (e) => {
    setSize(e.target.value)
  }

  

  const changeMsrp = (e) => {
    setMsrp(e.target.value)
  }

  const changeSprice = (e) => {
    setSprice(e.target.value)
  }

  const changeTarget = (e) => {
    setOwned(e.target.value)
  }


  
  const editItem = () =>{
    console.log("edit")
    const obj = { "data": { "item_id" : data.id, "serial_number": data.manifest.serial_number, "product_name": data.manifest.product_name, "item": { "item_barcode": data.item_barcode, "tgt_title": title, "tgt_brand": brand, "tgt_category": category, "tgt_model": model, "tgt_image": data.tgt_image, "tgt_color": color, "tgt_size": size, "tgt_weight": { "code": weightcode, "value": weight }, "tgt_dimensions": { "depth": depth, "height": height, "units": unit, "width": width }, "targetowned": targetowned, "tgt_msrp": msrp, "tgt_saleprice": Saleprice }, "encrypted": false }, "encoded_data": "yes" }
  
    axios.post(baseUrl + "/item/update_item/",obj ,{"headers":header})
    .then(response => {
      setUpdated(true)
      toast("Data Updated")
      
    })
  }


  const changeWeight = (e) => {
    setWeight(e.target.value)
  }
  const changeWeightCode = (e) => {
    setWeightCode(e.target.value)
  }

  const changeH = (e) => {
    setH(e.target.value)
  }
  const changeW = (e) => {
    setW(e.target.value)
  }
  const changeD = (e) => {
    setD(e.target.value)
  }
  const changeU = (e) => {
    setU(e.target.value)
  }





  return (
    <div>
      <Nav />
 
      <div className="row">
        <div className="col-md-3 col-3">
          <Sidebar1 />
        </div>
        
        <div className="col-md-9 col-9">
          <section className=" col-md-12 innerr" >
            <div className="container-fluid">




              <div className="row mar-t">
                <div className="col-md-2">
                </div>
                <td><button onClick={editItem} type="button" className='save'>Save</button></td>
                <td><Link to="/item"><button type="button" className='cancle' >Cancel</button></Link></td>
                <div className="loginBLock">
                  <div>

                      <div className="row ">
                        <div className="col md-6">
                          <label className='sign'><b>TGT Image</b></label>
                       <div className= "image-border" >
                           <img   className='tgti' src={
                            data.tgt_image ? `${data.tgt_image.baseUrl + data.tgt_image.primaryImage} ` : ""
                          }/>
                          </div>
                           
                        
                        </div>
                        <div className="col md-6 mt-2">
                        <div>
                          <label className='sign'><b>Item Barcode</b></label>
                          <input style={{"cursor":"not-allowed"}} disabled type="text" className="form-control" value={data.item_barcode} />
                          </div>
                          <div>
                          <label className='signn'><b>TGT Brand</b></label>
                          <input onChange={changeBrand} name="tgt_brand" type="text" className="form-control" value={brand}/>
                          </div>
                          <div>
                          <label className='signn'><b>TGT Category</b></label>
                          <input onChange={changeCategory} type="text" name="tgt_category" className="form-control" value={category} />
                          </div>
                          <div>
                          <label className='signn'><b>TGT Color</b></label>
                          <input onChange={changColor} className="form-control"  value={color} />
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col" >
                          <label className='signn'><b>TGT Title</b></label>
                          <input onChange={changTitle} type="text" className="form-control" value={title}  />
                        </div></div>
                        <div className="row ">
                        <div className="col">
                          <label className='signn'><b>TGT Model</b></label>
                          <input onChange={changeModel} type="text" className="form-control"value={model}/>
                        </div>
                        <div className="col">
                          <label className='signn'><b>TGT Size</b></label>
                          <input onChange={changeSize} type="text" className="form-control" value={size}/>
                        </div>
                        </div>

                      <div className="row">

                        <div className="col-md-3">
                          <label className='signn'><b>TGT Weight</b></label>
                          <input type="text" name='value' onChange={changeWeight} className="form-control" value={weight} />
                        </div>
                        <div className="col-md-3">
                        <label  className='signn'><b>TGT Weight Unit</b></label>
                          <input type="text" name='code' onChange={changeWeightCode} className="form-control" value={weightcode} />
                        </div>



                        <div className="col-md-6">
                          <label className='signn'><b>TGT Dimensions</b>  (H x W x D)</label>

                          <div style={{"display" : "inline-flex"}} className='row'>

                          <div className='col'>
                          <input onChange={changeH} type="text" className="form-control" value={height}/>
                          </div>
                          

                          <div className='col'>
                          <input onChange={changeW} type="text" className="form-control" value={width}/>
                          </div>

                          <div className='col'>
                          <input onChange={changeD} type="text" className="form-control" value={depth}/>
                          </div>

                          <div className='col'>
                          <input onChange={changeU} type="text" className="form-control" value={unit}/>
                          </div>

                          </div>

                         
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label className='sign'><b>TGT MSRP</b></label>
                          <input onChange={changeMsrp} type="text" className="form-control" value={msrp}/>
                        </div>
                        <div className="col">
                          <label className='sign'><b>TGT Saleprice</b></label>
                          <input onChange={changeSprice} type="text" className="form-control" value={Saleprice} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label className='sign'><b>TGT owned</b></label>
                          <input onChange={changeTarget} type="text" className="form-control"  value={targetowned}/>
                        </div>
                        <div className="col">

                        </div>
                        <div className='tgt'></div>
                      </div> 
                     
                       
            
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <ToastContainer />
    </div>
    
  )
}
export default Itemedit