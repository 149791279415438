
import Nav from "../Nav"
import Sidebar1 from "./Sidebar1"
import { useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"


import { ToastContainer, toast } from 'react-toastify';

function Proceed3(){


    useEffect(() => {
        if (isAuth.email != 'admin@gmail.com.com') {
            navigate("/")
        }
    }, [])
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const msg = localStorage.getItem('msg')
    const pallet = localStorage.getItem('pallet')
    // const location = useLocation()
    // const data = location.state
  
    const navigate = useNavigate()
 
    useEffect(() => {
        if (!isAuth) {
            navigate("/")
        }

        if (!pallet) {
            navigate("/remove")
        }
    }, [])

    toast(msg)

    function removelocal() {
        localStorage.removeItem("pallet")
        localStorage.removeItem("msg")
    }

    setTimeout (removelocal, 2000)
   
    return(
        <div>
            <Nav />
            <div className="row">
                <div className="col-md-3 col-3">
                    <Sidebar1 />
                </div>
                <div className="col-md-9 col-9"> 
                    <section className="inner">
                        <div className="adminLog"  >
                            <div className="loginBLock" >
                                <div>
                                    <div className="nav">
                                        <Link to="/home"><img className="clo"src="/close.png"/></Link>
                                    </div>
                                </div>
                                <center>
                                    <div> 
                                        <div>
                                            <img src="/delete.png" className="rep"style={{"width":"130px"}}/><h5 style={{"color":"#007FFF" ,"margin-top":"30px"}}> item removed,logs saved 
                                            <br></br>Pallet ID : {pallet}</h5>
                                        </div>
                                        <div style={{"padding":"94px"}}> 
                                        </div>
                                    </div>
                                </center>
                                <div className="loginForm">
                                </div>
                                <ToastContainer/>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
export default Proceed3