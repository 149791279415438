import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { baseUrl } from '../Config'
import Nav from '../Nav'
import Sidebar1 from './Sidebar1'
import { ToastContainer, toast } from "react-toastify"
import { AiOutlineEye, AiOutlineEyeInvisible, } from "react-icons/ai";


function Useredit() {
  const navigate =useNavigate()
  const [fetch, setUser] = useState()
  const [pw, setPw] = useState()
  const [user, setU] = useState()
  const [email, setE] = useState()
  const [utype, setType] = useState()
  const [statuss, setStatus] = useState()
  const [updatemsg, setUpdatemsg] = useState()
  const [userpass, setUserp] = useState()
  const [userpass2, setUserp2] = useState()
  const [data, setArray] = useState([])
  const isAuth = JSON.parse(localStorage.getItem('user'))
  const location = useLocation()
  const user_id = location.state
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Token ${isAuth.token}`
  }

  const user_type = {
    1: "Admin", 2: "Manager", 3: "Supervisor", 4: "Associate"
  }

  const status = { 1: "Active", 2: "In-Active" }

  const obj = { "data": { "user_id": user_id, "encrypted": false }, "encoded_data": "yes" }



  useEffect(() => {
    if (isAuth.email != 'admin@gmail.com') {
      navigate("/")
    }
    axios.post('https://api.quiksku.com/api/v1/get/', obj, { "headers": header })
      .then(response => {
        setUser(response.data.data)
        setE(response.data.data.email)
        setU(response.data.data.username)
        setType(response.data.data.user_type)
        setStatus(response.data.data.status)
      })

    if (updatemsg) {
      toast(updatemsg)
    }
  }, [])


  const handleUserid = (e) => {

  }
  const handlePw = (e) => {
    const value = e.target.value
    setPw(value)

  }
  const handleU = (e) => {
    const value = e.target.value
    setU(value)

  }
  const handleE = (e) => {
    const value = e.target.value
    setE(value)

  }
  const handleTy = (e) => {
    const value = e.target.value
    setType(value)

  }

  const handltSt = (e) => {
    const value = e.target.value
    setStatus(value)

  }

  const handlenew = (e) => {
    const value = e.target.value
    setUserp(value)

  }

  const handlenewconf = (e) => {
    const value = e.target.value
    setUserp2(value)
 
  }








  const handleSubmit = (e) => {

    e.preventDefault()

    const dataa = {
      "email": email,
      "user_id": user_id,

      "password": pw,

      "user_type": Number(utype),

      "username": user,

      "status": Number(statuss),


      "encrypted": false

    }
    Object.keys(dataa).forEach(key => {
      if (dataa[key] === undefined || dataa[key] === 'NaN') {
        delete dataa[key];
      }
    })



    const obj = {"data": dataa, "encoded_data": "yes" }






    axios.post(baseUrl + '/update/', obj, { "headers": header })
      .then(response => {

        toast(response.data.message)

      })
      .catch(error => {
        alert(JSON.stringify(error.response.data.message))
      })
  }


  const handlePass = () => {
    const frm = document.getElementById("password-form")

    if (frm.style.display === "none") {

      frm.style.display = 'block';
    }
  }

  const changePassword = (e) => {
    e.preventDefault()
    const frm = document.getElementById("password-form")
  
    if (userpass === userpass2) {
      const obj = { "data": { "user_id": user_id, "password": userpass, "encrypted": false }, "encoded_data": "yes" }
      axios.post(baseUrl + '/update/password/', obj, { "headers": header })
        .then(response => {

          toast(response.data.message)
            .then(frm.style.display = "none")

        })
    }
    else {
      toast("Passwords do NOT match!")
    }
  }
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordConfirmShow, setPasswordConfirmShow] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShow(!passwordShow);
  };
  const togglePasswordConfirmVisiblity = () => {
    setPasswordConfirmShow(!passwordConfirmShow);
  };
  return (
    <div> < Nav />
      <div className="row">
        <div className="col-md-3 col-3">
          <Sidebar1 />
        </div>
        <div className="col-md-9 col-9">
          <section className="inner">
            <div className="container">
              <div className="row mar-t">
                <div className="col-md-2">
                </div>
                <td><Link to=""><button onClick={handleSubmit} type="button" className='save'>Save</button></Link></td>
                <td><Link to="/user"><button type="button" className='cancle' >Cancel</button></Link></td>
                <div className="loginblock">
                  <div>
                    <form>

                      <div className="row ">
                        <div className="col">
                          <label className='sign' >Username</label>
                          <input type="text" required onChange={handleU} className="form-control input" value={user} />
                        </div>
                        <div className="col">
                          <label className='sign'>Email ID</label>
                          <input type="text" required onChange={handleE} className="form-control input" value={email} />
                        </div>
                      </div>


                      <div className="row ">
                        <div className="col">
                          <label className='sign'>User Type</label>
                          <select required onChange={handleTy} className="form-select" id="inputGroupSelect01">


                         {fetch?.user_type?  <option disabled selected value={fetch?.user_type}>{user_type[fetch?.user_type]}</option>
                         : ""}
                            <option value="4">Associate</option>
                            <option value="3">Supervisor</option>
                            <option value="2">Manager</option>
                            <option value="1">Admin</option>
                          </select>
                        </div>
                        <div className="col">
                          <label className='sign'>User Status</label>
                          <select required onChange={handltSt} className="form-select" id="inputGroupSelect01">

                            {
                              fetch?.status ? <option disabled selected value={fetch.status}>{status[fetch?.status]}</option> : ""
                            }


                            <option value="1">Active</option>
                            <option value="2">Inactive</option>

                          </select>
                        </div>
                      </div>




                    </form>


                    <ToastContainer />
                    <div className='row'>
                      <div className='col-md-3 mt-4'>
                        <button  onClick={handlePass} type="button" className='changepass'>Change password</button>
                      </div>

                    </div>
                  
                    <form style={{ "display": "none" }} id="password-form">
                      <div className="row " >
                        <div className="col-md-5 col-5 ">
                        <label className='sign'>User Password</label>
                        <p>
                        <input     type={passwordShow ? "text" : "password"} name="password"onChange={handlenew} className="form-control input" placeholder="******" />
                            <i className="far fa-eye toggle" id="togglePassword"></i>
                            <i onClick={togglePasswordVisiblity} position="absolute">
                              {passwordShow ? (
                                <AiOutlineEye />
                              ) : (
                                <AiOutlineEyeInvisible />
                              )}
                            </i>
                          </p>
                         
                         
                        </div>
                        <div className="col-md-5 col-5">
                          <label className='sign'>Confirm User Password</label>
                          <p>
                        <input     type={passwordConfirmShow ? "text" : "password"} name="password"onChange={handlenewconf} className="form-control input" placeholder="******" />
                            <i className="far fa-eye toggle" id="togglePassword"></i>
                            <i onClick={togglePasswordConfirmVisiblity} position="absolute">
                              {passwordConfirmShow ? (
                                <AiOutlineEye />
                              ) : (
                                <AiOutlineEyeInvisible />
                              )}
                            </i>
                          </p>
                      
                        </div>

                        <div  className="col-md-2 col-2 ">
                          <label className='sign'></label>
                          <button className='savepass' onClick={changePassword} type='submit'>Save</button>
                        </div>

                      </div>

                    </form>

                  </div>
                </div>
              </div>



            </div>


          </section></div></div></div>

  )
}
export default Useredit