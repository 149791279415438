import { useEffect,useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer ,toast } from "react-toastify"
import { baseUrl } from "../Config"
import Nav from "../Nav"
import axios from "axios";
import { Modal } from "react-bootstrap";

import Sidebar1 from "./Sidebar1"
import { ApiOutlined, Close } from "@mui/icons-material"
function Manifest_start3(){
    const [error, setError] = useState(false);
    const closePopup = () => setError(false);
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const [pallet , setPAllet] = useState()

    useEffect(() => {
        if (!isAuth) {
            navigate("/")
        }
    }, [])

    const header ={
        "Content-Type" : "application/json",
        "Authorization" : `Token ${isAuth.token}`
    }

    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
    };

    const handlePallet = (e) => {
        setPAllet(e.target.value)
    }

    const handlemsg = () => {
        toast("PLEASE ENTER PALLET ID")
    }

    const handleSubmit = () =>{
        const obj = {"data":{"serial_number": pallet,"encrypted":false},"encoded_data":"yes"}
        axios.post(baseUrl + '/item/get_serialnumber/',obj,{"headers" : header})
        
  
        .then((response) => {
            // setData(response.data.data)

       
            navigate("/manifeststop",{"state":pallet})
            
        },(error) => {
        

            setError(true)
            toast("This Pallet id does not exist")
          }
        )
        
    }
      
    return(
        <div>
       <Nav />
         
         <div className="row">


         <div className="col-md-3 col-3">
         <Sidebar1 />
         </div>
         
         <div className="col-md-9 col-9"> <section className="inner">
                <div className="adminLog" >
                    <div className="loginBLock" >
                    <div className="row">
                <div className="col-md-10 col-10"></div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
                        <center>
                            <div></div>
                            <div className="imgg">
                                <img src="/mainfest_grey.png" className="im" /><br></br><br></br>
                                <h5 className="in">ENTER PALLET ID</h5>
                                <div >
                                  
                                  <div className="inpu">
                                  <p>
                                        <input  onChange={handlePallet} required type="email" className="form-control input-sm" id="email"
                                           
                                            placeholder="Barcode (only number)" />
                                        <i className="far" id="togglePassword"></i>
                                        <button onClick={handleReset} className="imer" >
                                            <img src="/text_clear.png"className="imr"  position="absolute"
                                            ></img></button>
                                    </p></div>
                                   
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-md-4 col-4"></div>
                            <div className="col-md-4 col-4">
                                <div >
                                {
                                       pallet ? 
                               
                                <button onClick={handleSubmit} type="button" className="but" ><p className="io">Start</p></button>
                               :
                               <Link onClick={handlemsg} to=''> <button type="button" className="but" ><p className="io">Start</p></button></Link>
                            }
                                <ToastContainer />
                                </div>
                                </div>  <div className="col-md-4 col-4"></div>
                                </div>
                        </center>
                        <div className="padiing">
                    </div>
                    </div>
                </div>
            </section>
            
            </div></div>
           <ToastContainer />
        </div>
    )
}
export default Manifest_start3