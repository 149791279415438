import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { baseUrl } from "../Config"
import Nav from "../Nav"
import Sidebar1 from "./Sidebar1"
function New3() {

  
    const [colorSet,colorSetSet]=useState()
    const [text,setText]=useState("TGT-HC")
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuth) {
          navigate("/");
        }
        
      }, []);
    const location = useLocation()
    // const data = location.state
    const obj = {"data":{"encrypted":false},"encoded_data":"yes"}
    const [list , setList] = useState()
    const [data , setData]= useState({id: 1, key: "tgt-hc", value: "TGT-HC", description: "TGT HIGH COUNT"})




   
    useEffect(() => {       
        if(!isAuth) {
            navigate("/")
        }
    }, [])

    const header ={
        "Content-Type" : "application/json",
        "Authorization" : `Token ${isAuth.token}`
    }

    


    useEffect( ()=> {
        axios.post(baseUrl + '/category/list/' ,obj,{"headers" : header})
        .then(response => setList(response.data.data))
    },[])

    

    
    
    const generate_obj = {"data": {"product": data?.id,"product_name": data?.value,"warehouse_id": "DC001","encrypted": false}, "encoded_data": "yes" , "discription":data?.description }
    


    const handlePrint = () => {
        

    }


    


    const button1=()=>{
        colorSetSet("#FFFF00");
        setText("TGT-HC")
        let oo = list?.find(o => o?.value === "TGT-HC");
        setData(oo)
    }   

    const button2=()=>{
    colorSetSet("#2A5EEE");
    setText("TGT-GM")
    let oo = list?.find(o => o?.value === "TGT-GM");
    setData(oo)
    }
    const button3=()=>{
    colorSetSet("#FF00FF");
    setText("TGT-SH")
    let oo = list?.find(o => o?.value === "TGT-SH");
        setData(oo)
    }
    const button4=()=>{
    colorSetSet("#22B14C");
    setText("TGT-AP")
    let oo = list?.find(o => o?.value === "TGT-AP");
        setData(oo)
    }
    const button5=()=>{
    colorSetSet("#00FFFF");
    setText("TGT-UG")
    let oo = list?.find(o => o?.value === "TGT-UG");
        setData(oo)
    }
    const button6=()=>{
    colorSetSet("#FF0000");
    setText("MISC")
    let oo = list?.find(o => o?.value === "MISC");
        setData(oo)
    }
                                                             
   
    return (
        <div>
        <Nav />
   
        <div className="row">
          <div className="col-md-3 col-3">
            <Sidebar1 />
          </div>
          
          <div className="col-md-9 col-9">
            <section className="  inner" >
              <div className="container-fluid">
                            <center>
                            <div className="row mar-t">
                                
                                <div className="col-md-4 col-4">
                                    <button  type="button" className="wth ir" onClick={button1} ><b className="io">TGT-HC</b></button>
                                </div>
                                <div className="col-md-4 col-4">
                                    <button  type="button" className="wth iw" onClick={button2}><b className="io">TGT-GM</b></button>
                                </div>
                                <div className="col-md-4 col-4">
                                    <button type="button" className="wth ia" onClick={button3}><b className="io">TGT-SH</b></button>
                                </div>
                                
                              
                            </div>
                            </center>
                            <center>
                            <div className="row mt-3">
                          
                                <div className="col-md-4 col-4">
                                    <button type="button" className="wth ib" onClick={button4}><b className="io">TGT-AP</b></button>
                                </div>
                                <div className="col-md-4 col-4">
                                    <button type="button" className="wth ic" onClick={button5}><b className="io">TGT-UG</b></button>
                                </div>
                                <div className="col-md-4 col-4">
                                    <button type="button" className="wth id" onClick={button6}><b className="io">MISC</b></button>
                                </div>
                                
                              
                            </div>
                       
                            </center>
                            <div className="loginBLockc">
                                
                            <div className="row">
                <div className="col-md-10 col-10"></div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
                            <div className="row mar-t" >
                  
                            <div className="col-md-3 col-3"></div>
                            <div className="col-md-6 col-6">
                                   
                                        <div className="miscc width-b mb-4" style={{backgroundColor:colorSet}}>
                                           <center>
                                                <h5 className="tt">{text}</h5>
                                                </center>
                                        </div>
                               
                                </div>
                                <div className="col-md-3 col-3"></div></div>
                                <center>
                                <Link to='/barcode' state={{generate_obj}} onClick={handlePrint}><button className="pri">Print</button></Link></center>
                                <div className="pading"></div>  </div> <div className="pading"></div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
export default New3