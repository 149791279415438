import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Nav from "../Nav"

import Sidebar1 from "./Sidebar1"
function Home2() {
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
 
    useEffect(() => {
        if (!isAuth) {
          navigate("/");
        }
        
      }, []);
    return (
        <div>
      <Nav />
 
      <div className="row">
        <div className="col-md-3 col-3">
          <Sidebar1 />
        </div>
        
        <div className="col-md-9 col-9">
          <section className="  inner" >
            <div className="container-fluid">
                            <div className="row mar-t">
                                <div className="col-">
                                </div>
                                <div className="col-md-4">
                                    <Link to="/new" className="l">
                                        <div className="neww width-b mb-4">
                                            <center><img src="/New.png" className="ne" />
                                                <h5 className="n">NEW</h5></center>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link to="/manifeststart" className="l" >
                                        <div className="manifestt width-b mb-4" >
                                            <center><img src="/manifest.png" className="ne" />
                                                <h5 className="n">MANIFEST</h5></center>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link to="/reprint" className="l">
                                        <div className="reprintt width-b mb-4">
                                            <center><img src="/Reprint.png" className="ne" />
                                                <h5 className="n">REPRINT</h5></center>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-">
                                </div>
                                <div className="col-md-4">
                                    <Link to="/download" className="l">
                                        <div className="download width-b mb-4">
                                            <center><img src="/download.png" className="ne" />
                                                <h5 className="n">DOWNLOAD</h5></center>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link to="/remove" className="l">
                                        <div className="removee width-b mb-4">
                                            <center><img src="/remove.png" className="ne" />
                                                <h5 className="n">REMOVE</h5></center>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link to="/user" className="l">
                                        <div className="userss width-b mb-4">
                                            <center><img src="/user.png" className="ne" />
                                                <h5 className="n">USERS</h5></center>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-">
                                </div>
                                <div className="col-md-4">
                                    <Link to="/item" className="l">
                                        <div className="items width-a mb-4 ">
                                            <center><img src="/item.png" className="ne" />
                                                <h5 className="n">ITEM</h5></center>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-md-4">
                                    <Link to="/inventory/list" className="l">
                                        <div className="inventoryy width-a">
                                            <center><img src="/inventory.png" className="ne" />
                                                <h5 className="n">INVENTORY</h5></center>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="padiing"></div>
                        </div>
                        <div className="adminLog">
                        </div>

                    </section>
                </div>

            </div>



        </div>
    )
}
export default Home2