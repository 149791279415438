
import Nav from "../Nav"

import Sidebar1 from "./Sidebar1"
import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { ToastContainer, toast } from 'react-toastify';
import { baseUrl } from "../Config";
function Manifest_stop3(){
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const location = useLocation()
    const data = location.state
    // console.log(data, "ppppppppppp")
    const obj = {"data":{"serial_number": data,"encrypted":false},"encoded_data":"yes"}
    const [palletdata , setData ] = useState()
    const [barcode , setBarcode ] = useState()
    const [colorf , setColor ] = useState()
    const [price , setPrice ] = useState({})
    // var price  = {}
    const [targetdata , setTarget ] = useState({})
    const [tcin , setTcin ] = useState()
    const [targetresponse , setTresponse ] = useState(false)
    const [priceresponse , setPresponse ] = useState(false)
    // const price = JSON.parse(localStorage.getItem("price"))
    // const targetdata = JSON.parse(localStorage.getItem("targetdata"))
    var tgt_msrp = ""
    var tgt_saleprice = ""
    const header ={
        "Content-Type" : "application/json",
        "Authorization" : `Token ${isAuth.token}`
    }
    useEffect(() => {
        if (!isAuth) {
            navigate("/")
        }
        if (!data) {
            navigate("/manifeststart")
        }
    }, [])

    
    
    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        
    };


    useEffect(() => {
        axios.post(baseUrl + '/item/get_serialnumber/',obj,{"headers" : header})
        
  
        .then(response => setData(response.data.data))
      } , [])

    

    const handleBarcode = (e) => {
            setBarcode(e.target.value)       
        
    }

    

    const handleSubmit = (e) => {
        e.preventDefault()

        const obj = {"data": {"serial_number": palletdata.serial_number,"product_name": palletdata.product_name,"barcode": barcode,"encrypted": false},"encoded_data": "yes"}
        axios.post(baseUrl + '/item/save_itemdata/',obj,{"headers" : header})
        .then(response => toast("ITEM ADDED SUCCESSFULLY"))
        .catch(error => {
            toast("MANIFEST NOT SAVE")
        })
    
    }

    

    
    

    return(
        <div>
       <Nav  />
         
         
         <div className="row">


         <div className="col-md-3 col-3">
         <Sidebar1 />
         </div>
         
         <div className="col-md-9 col-9"> <section className="inner">
                <div className="adminLog" >
                    <div className="loginBLock" >
                    <div className="row">
                <div className="col-md-10 col-10"></div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
                        <center>
                            <div> 
                                <div> 
                                    <h4 className="scan" >SCAN BAR CODE</h4>
                                </div>
                                <div>
                                    <img src="/scan_bar.png" className="ima" /><br></br>
                                    <h5 className="in" >PALLET ID</h5>
                                    <h5 className="in">{data}</h5>
                                    <div><div className="inpu">
                                    <div>
                                    <form onSubmit={handleSubmit}>
                                        <input onChange={handleBarcode}   type="text" className="form-control input-sm" 
                                          
                                            placeholder="Barcode(only number)" />
                                            
                                        <i className="far" id="togglePassword"></i>
                                        <button onClick={handleReset} className="imer">
                                            <img src="/text_clear.png" className="imr" position="absolute"
                                            ></img></button>
                                            </form>
                                    </div></div>
                                </div>
                            </div>
                            <Link to="/home"><button type="button" className="buttn"><p className="io">Stop</p></button></Link>
                           </div>
                           <ToastContainer />
                        </center>
                        <div className="padiing">
                    </div>
                    </div>
                </div>
            </section> </div></div>
         
        </div>
    )
}
export default Manifest_stop3