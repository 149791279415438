import { Edit } from "@mui/icons-material"
import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Nav from "../Nav"
import Pagination from "./Pagination"
import Sidebar1 from "./Sidebar1"
import { baseUrl } from '../Config'


function User() {

    const [users, setUsers] = useState([])
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const status = { 1: "Active", 2: "In-Active" }
    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(10);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const header = {
        "Content-Type": "application/json",
        "Authorization": `Token ${isAuth.token}`
    }
    const obj = {
        "data": {
            "encrypted": false
        },
        "encoded_data": "yes"
    }

    useEffect(() => {
        if (!isAuth) {
            navigate("/")
        }

        axios.post(baseUrl + '/user-list/', obj, { "headers": header })
            .then(response => {
                setUsers(response.data.data.reverse())
            })

    }, [])




    const handleEdit = (id) => {

        navigate('/user/edit', { "state": id })

    }

    const currentRecords = users.slice(indexOfFirstRecord,
        indexOfLastRecord);

    const nPages = Math.ceil(users.length / recordsPerPage)

    return (

        <>
            < Nav />
            <div className="row">
                <div className="col-md-3 col-3">
                    <Sidebar1 />
                </div>
                <div className="col-md-9 col-9">
                    <section className="inner">
                        <div className="container">
                            <div className="row mar-t">
                                <div className="col-md-2">
                                </div>

                                <Link to="/signup"><button type="button" className="savev"><b>Add New</b></button></Link>
                                <div className="logblock">
                                    <>
                                        <table className="table table-borderless">

                                            <thead className="userrrr"  >
                                                <tr>
                                                    <td scope='col' className='eit'><b>User ID</b></td>
                                                    <td scope='col' className='eit'><b>User Name</b></td>
                                                    <td scope='col' className='eit'><b>User Type</b></td>
                                                    <td scope='col' className='eit'><b>User PW</b></td>
                                                    <td scope='col' className='eit'><b>User Status</b></td>
                                                    <td scope='col' className='eit'><b>Edit</b></td>
                                                </tr>
                                            </thead>
                                            <tbody className="eit">
                                                {
                                                    currentRecords.map(user => <tr key={user.id}>
                                                        <td>{user.user_code}</td>
                                                        <td>{user.username}</td>
                                                        <td>{user.user_type_value}</td>
                                                        <td>********</td>
                                                        <td>{status[user.status]}</td>

                                                        <td className="cursor" data-toggle="tooltip" data-placement="right" title="Edit" onClick={() => handleEdit(user.id)}><Edit /></td>
                                                    </tr>)
                                                }


                                            </tbody>
                                        </table>
                                        <br></br>
                                        <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                    </>
                                </div>

                            </div></div></section></div></div>

        </>
    )
}
export default User