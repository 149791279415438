import React from 'react'

import { Link, useLocation } from "react-router-dom"
import { useState, useEffect } from "react";
import { CSVLink,  } from "react-csv";
import Nav from '../Nav';
import Sidebar1 from './Sidebar1';
import axios from 'axios';
import { baseUrl } from '../Config';
import { toast, ToastContainer } from 'react-toastify';


function Download_list() {
  const isAuth = JSON.parse(localStorage.getItem('user'))
  

  
  const [csvdata , setCsv ] = useState([])
  const location = useLocation()
  const data = location.state
  
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedd, setChecked] = useState([])
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [newchecked ,setNew] = useState([])
  const [dataa,setData] = useState()
  const [data_new,setDatanew] = useState([])
  const [is_processed, setProcess] = useState(false);
  const [len,setLen ] = useState('')




  const toggleCheck1 = (e) => {

    // console.log(e.target)

    const {value , checked } = e.target


    if (checked === true ){
      setNew([...newchecked , [Number(value)]])
      setChecked([...checkedd , Number(value)])
    }else{
      
      setNew(newchecked.filter((e) =>  e[0] !== Number(value) ))     
      setChecked(checkedd.filter((e) =>  e !== Number(value) ))

      
    } 
    
   


  }





  useEffect(() => {
    setList(data);

  }, [list]);


  






const selectAll = (value) => {
    
  const checked = value
  
  setCheckedAll(value);


  if (checked === true ){
    data.map(item => {
      
      setNew(prev=> [...prev , [item.serial_number]])
      setChecked(prev=> [...prev , item.serial_number])
    })
  }else{
    data.map(item => {
      setChecked("")
      setNew("")
    })
    
    
  }

  
  // setChecked((prevState) => {
  //   console.log(prevState)
  //   const newState = { ...prevState };
  //   for (const inputName in newState) {
  //     newState[inputName] = value;
  //   }
  //   return newState;
  // });
};

 




  const csvData = [
    
    // checkedd.map(item => {
    //   return item
    // })
  ];



  const handleApi = (e) => {
  
    
  
  
    const header ={
      "Content-Type" : "application/json",
      "Authorization" : `Token ${isAuth.token}`
    }

    const obj = {"data":{"serial_number_list":checkedd,"encrypted":false},"encoded_data":"yes"}

   
    
  
    
    axios.post(baseUrl + '/item/filter_item/' , obj , {"headers" : header} )
    .then(response => {
     
      setData(response.data.data)
      setLen(response.data.data.length)
      response.data.data.forEach(item => setDatanew(prev=> [...prev , item.item ]))
      setProcess(true)      
      
    })
   
  
  
  }
  
  if (is_processed === true && dataa?.length === len && data_new?.length === len) {
    setTimeout(csv_down,3000)
  }

  function csv_down() {
    const button = document.getElementById('csv_but')
    button?.click()
    setProcess(false)
  }

  
  const headerss = [
    { label: "serialnumber", key: "serial_number" },
    { label: "product_name", key: "product_name" },
    { label: "barcode", key: "item_barcode" },
    { label: "title", key: "tgt_title" },
    { label: "msrp", key: "tgt_msrp" },
    { label: "category", key: "tgt_category" },
    { label: "brand", key: "tgt_brand" },
  ];

  const csvReport = {
    data: dataa,
    headers: headerss,
    filename: 'data.csv'
  };


 






  return (
    <div>
    <Nav />
    <div className="row">
      <div className="col-md-3 col-3">
        <Sidebar1 />
      </div>


      <div className="col-md-9 col-9">    
        <section className="inner">
          <div className="adminLog" >
            <div className="loginBLock">
            <div className="row">
                <div className="col-md-10 col-10">
                <div><h5 className="ine">Manifest DL Download</h5></div>
                </div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
              <div className="form-check md-4" >
                <input className="form-check-input" type="checkbox" onChange={(event) => selectAll(event.target.checked)}
                  checked={checkedAll} />
              <h5 className="selectall">Select All</h5>
              </div><br></br>

            <div className="row lis mt-1">
              


              <div className='container'>
                
                <div className="col-md-12 itemsstyle">

              {
                data.map(item => <div className="form-check form-check-inline" >
                <input className="form-check-input" type="checkbox" name={item.serial_number}  value={item.serial_number}
                  onChange={toggleCheck1}
                  checked={checkedd.includes(item.serial_number)} />
                <label className="form-check-label" htmlFor="flexCheckDefault" key="uniqueId1">
                  {item.serial_number}
                </label>
             </div>)
              }
              </div>
              </div>

            </div>

<br />
<br />



      
            
               
<div className="row">
                            <div className="col-md-4 col-4"></div>
                            <div className="col-md-4 col-4">
                                <div >
    
                                <button onClick={handleApi}  type="button" className="butyy"><p className="io">Download</p></button>
                                <CSVLink hidden id="csv_but" filename='data.csv' data={data_new} headers={headerss}><button type="button"  className="buty"><p className="io">Download</p></button></CSVLink>
                            <ToastContainer />
                            </div>
                                </div>  <div className="col-md-4 col-4"></div>
                                </div>
        

             
            
      
            
            
         
          
            <div className='pading'></div>
            </div>

            
     
        </div>
       </section>
        </div></div>

    </div>
  )
}

export default Download_list