
import { Link, useNavigate } from "react-router-dom"
import Nav from "../Nav"
import { useEffect, useState } from "react"

import Printpage from "../Printpage"
import { ToastContainer, toast } from 'react-toastify';
import Sidebar1 from "./Sidebar1"
function Reprint3(){
    const isAuth = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const [pallet , setPallet] = useState()
    const [msg ,setMsg] = useState("")

    const [error, setError] = useState(null)

 

    const obj ={"data":{"serial_number": pallet,"encrypted":false},"encoded_data":"yes" }

    useEffect(()=>{
        if(!isAuth){
            navigate("/")
        }
    }, [])
    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({
            itemvalues: [{}]
        });
    };

    const handlePallet = (e) => {
        setPallet(e.target.value)
    }



   

    const handlemsg = () => {
        toast("PLEASE ENTER PALLET ID")
    }
    return(
        <div>
    <Nav />
         
         
         <div className="row">


         <div className="col-md-3 col-3">
         <Sidebar1 />
         </div>
         
         <div className="col-md-9 col-9"> <section className="inner">
                <div className="adminLog">
                    <div className="loginBLock" >
                    
                    <div className="row">
                <div className="col-md-10 col-10"></div>
                <div className="col-md-2 col-2">
                        <div className="nav">
                            <Link to="/home"><img className="clo" src="/close.png" /></Link>
                        </div>
                        </div>
                        </div>
                        <center>
                   
                            <img src="/print.png" className="im" /><br></br><br></br>
                                <h5 className="in">ENTER PALLET ID</h5>
                                <div>  <div >
                                  
                                  <div className="inpu">
                                    <p>
                                        <input onChange={handlePallet} type="text"
        pattern="[0-9]*" className="form-control input-sm" id="email"
                                           
                                            placeholder="Enter Pallet id (only number)" />
                                        <i className="far" id="togglePassword"></i>
                                        <button onClick={handleReset} className="imer" >
                                            <img src="/text_clear.png" className="imr" position="absolute"
                                            ></img></button>
                                    </p></div>
                                   
                                </div>
                                    <div>{msg}</div>
                               
                            </div> <div className="row">
                            <div className="col-md-4 col-4"></div>
                            <div className="col-md-4 col-4">
                                <div >

                            {
                                pallet ? 
                                <Link to='/reprint/barcode' state={obj}><button disabled type="button" className="butt"><p className="io">Reprint</p></button></Link>
                                :
                                <Link onClick={handlemsg} to=''><button disabled  type="button" className="butt"><p className="io">Reprint</p></button></Link>

                            }
                            
                            <ToastContainer />
                            </div>
                                </div>  <div className="col-md-4 col-4"></div>
                                </div>
                          
                           

                        </center>
                        <div className="padiing">
                    </div>
                    </div>
                </div>
            </section></div></div>
            
        </div>
    )
}
export default Reprint3