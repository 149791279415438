import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom"
import Nav from '../Nav'
import Sidebar1 from './Sidebar1'
import { ToastContainer, toast } from "react-toastify"
import Pagination from './Pagination'
import axios from 'axios'
import { Delete, Edit, RemoveRedEye } from '@mui/icons-material'
import { baseUrl } from '../Config'

function InventoryL() {
    const user = JSON.parse(localStorage.getItem('user'))
    const location = useLocation()
    const pallet = location.state
    const navigate = useNavigate()

    const [data, setData] = useState()
    const [tmisc, setM] = useState()
    const [thc, setHc] = useState()
    const [tgm, setGm] = useState()
    const [tsh, setSh] = useState()
    const [tap, setAp] = useState()
    const [tug, setUp] = useState()
    const [searchdata, setsearchdata] = useState('')
    const [is_removed, setRe] = useState(false)
    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(25);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const header = {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
    }



    useEffect(() => {
        const obj = { "data": { "encrypted": false }, "encoded_data": "yes" }
        axios.post(baseUrl + "/item/inventory_list/", obj, { "headers": header })
            .then(response => {

                setData(response.data.data.data)

                // if (response.data.data.length == 0 ){
                //     navigate('/inventory' , {state:"No data found"})
                // }
                const misc = response.data.data.data?.filter((item) => (item?.product_name == 'MISC'))
                const hc = response.data.data.data?.filter((item) => (item?.product_name == 'TGT-HC'))
                const gm = response.data.data.data?.filter((item) => (item?.product_name == 'TGT-GM'))
                const sh = response.data.data.data?.filter((item) => (item?.product_name == 'TGT-SH'))
                const ap = response.data.data.data?.filter((item) => (item?.product_name == 'TGT-AP'))
                const ug = response.data.data.data?.filter((item) => (item?.product_name == 'TGT-UG'))

                setM(misc.length)
                setSh(sh.length)
                setHc(hc.length)
                setGm(gm.length)
                setAp(ap.length)
                setUp(ug.length)
            })



    }, [])


    function handleDelete(serial, id) {

        const obj = { "data": { "serial_number": serial, "encrypted": false }, "encoded_data": "yes" }
        axios.post(baseUrl + '/item/change_invetory_status/', obj, { "headers": header })
            .then(response => {

            })

            .catch(error => {
                console.log(error)
            })
        setData((current) =>
            current.filter((item) => item.id !== id)
        );
        setRe(true)



    }

    if (is_removed === true) {
        const misc = data?.filter((item) => (item?.product_name == 'MISC'))
        const hc = data?.filter((item) => (item?.product_name == 'TGT-HC'))
        const gm = data?.filter((item) => (item?.product_name == 'TGT-GM'))
        const sh = data?.filter((item) => (item?.product_name == 'TGT-SH'))
        const ap = data?.filter((item) => (item?.product_name == 'TGT-AP'))
        const ug = data?.filter((item) => (item?.product_name == 'TGT-UG'))

        setM(misc.length)
        setSh(sh.length)
        setHc(hc.length)
        setGm(gm.length)
        setAp(ap.length)
        setUp(ug.length)
        setRe(false)
    }









    const currentRecords = data?.slice(indexOfFirstRecord,
        indexOfLastRecord);

    const nPages = Math.ceil(data?.length / recordsPerPage)


    const handletoggle = (serial, status) => {
        if (status === 1) {
            const obj = { "data": { "serial_number": serial, "status": 0, "encrypted": false }, "encoded_data": "yes" }
            axios.post(baseUrl + '/item/change_invetory_status/', obj, { "headers": header })
                .then(response => {

                })

        }
        console.log(serial, status, "DATA")
        if (status === 0) {
            const obj = { "data": { "serial_number": serial, "status": 1, "encrypted": false }, "encoded_data": "yes" }
            axios.post(baseUrl + '/item/change_invetory_status/', obj, { "headers": header })
                .then(response => {

                })

        }

    }
    const [dataa, setDataa] = useState([])
    const [first, setfirst] = useState(true)
    const search = () => {
        console.log("sdff", searchdata)


        const result = data.filter(({ serial_number }) => serial_number === parseInt(searchdata));
        setDataa(result)
        console.log("asddfghghwefryhrtjhtyjhj", dataa)
        setfirst(false)
        if (searchdata === null) {
            setfirst(true)


        }

    }

    // console.log(currentRecords,"rec")
    function handle(e) {
        setsearchdata(e.target.value)
        setDataa(currentRecords)

    }

    return (
        <div>
            <Nav />

            <div className="row">


                <div className="col-md-3 col-3">
                    <Sidebar1 />
                </div>

                <div className="col-md-9 col-9"> <section className="inner">
                    <div className="adminLog" >
                        <div className="loginBLock" >
                            <div className="row">

                                <div className="col-md-8 col-8">
                                    <div className="inpu" >
                                        <p>
                                            <input required type="email" className="form-control input-sm" id="email"placeholder="Serial Number" onChange={handle}/>
                                            <i className="far" id="togglePassword"></i>
                                            <button className='navv' onClick={search}>
                                                <img src="/go.png.png" className="imrr" position="absolute"
                                                ></img></button>
                                        </p></div>
                                </div>
                                <div className="col-md-2 col-2"></div>
                                <div className="col-md-2 col-2">
                                    <div className="nav">
                                        <Link to="/home"><img className="clo" src="/close.png" /></Link>
                                    </div>
                                </div>
                            </div>

                            <div className='container'>
                                <div className='margi'>
                                    <div className="row mar-t ">

                                        <div className="col-md-2">
                                            <div className="inven height-c mb-4">
                                                <b className='py-5 ioa'>TGT-HC</b><br></br><b className='iooo'>{thc}</b>
                                            </div>
                                        </div>



                                        <div className="col-md-2">
                                            <div className="inve height-c mb-4">

                                                <b className='ioa'>TGT-GM </b><br></br><b className='iooo'>{tgm}</b>
                                            </div>

                                        </div>

                                        <div className="col-md-2">

                                            <div className="invent height-c mb-4">

                                                <b className='ioa'>TGT-SH </b><br></br><b className='iooo'>{tsh}</b>

                                            </div>

                                        </div>



                                        <div className="col-md-2">

                                            <div className="inv height-c mb-4">

                                                <b className='ioa'>TGT-AP </b><br></br><b className='iooo'>{tap}</b>
                                            </div>

                                        </div>

                                        <div className="col-md-2">

                                            <div className="invento height-c mb-4">

                                                <b className='ioa'>TGT-UG </b><br></br><b className='iooo'>{tug}</b>
                                            </div>

                                        </div>



                                        <div className="col-md-2">

                                            <div className="inventor height-c mb-4">

                                                <b className='ioa'>MISC </b><br></br><b className='iooo'>{tmisc}</b>
                                            </div>

                                        </div>


                                    </div>
                                </div>

                                {/* <div className="row mar-t">
                                
                                <div className="col-md-2 col-2">
                                    <button  type="button" className="wtth ir"  >
                                        <b className="ioa">TGT-HC </b><br></br><b className="iooo">{thc}</b></button>
                                </div>
                                <div className="col-md-2 col-2">
                                    <button  type="button" className="wtth iw" ><b className="ioa">TGT-GM </b><br></br><b className="ioooo">{tgm}</b></button>
                                </div>
                                <div className="col-md-2 col-2">
                                    <button type="button" className="wtth ia" ><b className="ioa">TGT-SH </b><br></br><b className="ioo">{tsh}</b></button>
                                </div>
                                <div className="col-md-2 col-2">
                                    <button type="button" className="wtth ib" ><b className="ioa">TGT-AP </b><br></br><b className="ioo">{tap}</b></button>
                                </div>
                                <div className="col-md-2 col-2">
                                    <button type="button" className="wtth ic" ><b className="ioa">TGT-UG </b><br></br><b className="ioo">{tug}</b></button>
                                </div>
                                <div className="col-md-2 col-2">
                                    <button  type="button" className="wtth id" ><b className="iob">MISC   </b><br></br><b className="ioo">{tmisc}</b></button>
                                </div>
                                
                              
                            </div> */}


                                {/* <div className="row mar-t">
                                <div className="col-md-1">
                                </div>
                                <div className="col-md-3">
                                    <button style={{"cursor" : "default"}}  type="button" className="wth ir" ><p className="io">TGT-HC ({thc})</p></button>
                                </div>
                                <div className="col-md-3">
                                    <button style={{"cursor" : "default"}}  type="button" className="wth iw"><p className="io">TGT-GM ({tgm})</p></button>
                                </div>
                                <div className="col-md-3">
                                    <button style={{"cursor" : "default"}} type="button" className="wth ia"><p className="io">TGT-SH ({tsh})</p></button>
                                </div>
                                
                              
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-1">
                                </div>
                                <div className="col-md-3">
                                    <button style={{"cursor" : "default"}} type="button" className="wth ib"><p className="io">TGT-AP ({tap})</p></button>
                                </div>
                                <div className="col-md-3">
                                    <button style={{"cursor" : "default"}} type="button" className="wth ic"><p className="io">TGT-UG ({tug})</p></button>
                                </div>
                                <div className="col-md-3">
                                    <button style={{"cursor" : "default"}} type="button" className="wth id"><p className="io">MISC ({tmisc})</p></button>
                                </div>
                                
                              
                            </div> */}


                                <div className='row'>

                                    <div className="loginBLock mt-3">

                                        <div>
                                            <table className="table table-borderless">

                                                <thead className="userrrr"  >
                                                    <tr>
                                                        <td scope='col' className='eit'><b>Serial Number</b></td>
                                                        <td scope='col' className='eit'><b>Product Name</b></td>
                                                        <td scope='col' className='eit'><b>Item Count</b></td>
                                                        <td className='eit'><b>Active/Inactive</b></td>

                                                    </tr>
                                                </thead>
                                                <tbody className='eit'>

                                                    {
                                                        first && currentRecords?.map(item => (

                                                            <tr key={item.id}>
                                                                <td>{item.serial_number}</td>
                                                                <td>{item.product_name}</td>
                                                                <td>{item.manifest_count}</td>
                                                                <td><label className="switch">
                                                                    {item.is_deleted === 1 ? <input onChange={() => handletoggle(item.serial_number, item.is_deleted)} type="checkbox"></input> : <input onChange={() => handletoggle(item.serial_number, item.is_deleted)} checked type="checkbox"></input>

                                                                    }



                                                                    <span className="slider round"></span>
                                                                </label></td>
                                                                {/* <td  data-toggle="tooltip" data-placement="right" title="Remove" >< Delete onClick={() => handleDelete(item.serial_number , item.id)} style={{"cursor":"pointer"}} /></td> */}
                                                            </tr>



                                                        ))
                                                    }
                                                    {
                                                        !first && dataa?.map(item => (

                                                            <tr key={item.id}>
                                                                <td>{item.serial_number}</td>
                                                                <td>{item.product_name}</td>
                                                                <td>{item.manifest_count}</td>
                                                                <td><label className="switch">
                                                                    {item.is_deleted === 1 ? <input onClick={() => handletoggle(item.serial_number, item.is_deleted)} type="checkbox"></input> : <input onClick={() => handletoggle(item.serial_number, item.is_deleted)} checked type="checkbox"></input>

                                                                    }



                                                                    <span className="slider round"></span>
                                                                </label></td>
                                                                {/* <td  data-toggle="tooltip" data-placement="right" title="Remove" >< Delete onClick={() => handleDelete(item.serial_number , item.id)} style={{"cursor":"pointer"}} /></td> */}
                                                            </tr>



                                                        ))
                                                    }





                                                </tbody>

                                            </table>
                                            {
                                                (data?.length === 0) ? <center><p className='mt-4'>NO DATA FOUND</p></center> : <div></div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col'>
                                   <div className="loginBLock" style={{"width":"10vw","margin-left":"10px"}}>
                                       <div> 
                                           <table className="table">
                                               <thead className="userrrr">
                                                   <tr>
                                                     <center> <td scope='col' className='eit'>Edit</td></center> 
                                                   </tr>
                                               </thead>
                                               <tbody>
                                               {
                                                       data?.map(item => (
                                                           <tr>
                                                           <td><center> <Link to="/inventory/edit"><img src="/eye.png" className='editt'/></Link> </center></td>
                                                         </tr>
                                                       ))
                                                   }
                                                   
                                                   
                                               </tbody>
                                           </table>
                                           <div  className='padingg'></div>
                                       </div>
                                   </div>
                                   </div> */}



                                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                            </div>
                        </div></div>
                </section>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
export default InventoryL